import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import AdminLayout from './layouts/AdminLayout';
import Auth from './views/auth/signin/Auth';
import Chamber from './views/pages/Chamber/Chamber';
// import ApptTable from './views/tables/ApptTable';
// import Home from './views/pages/Home/Home';

import ProtectedRoute from './ProtectedRoute';

// import LabTest from './views/pages/Profile/LabTest/LabTest';
// import LabTestForm from './views/pages/Profile/LabTest/LabTestForm';
import EditDermaluxDailyConsentForm from './views/pages/consentforms/EditPages/EditDermaluxDailyConsentForm.js';
import EditIsotretinoinConsentForm from './views/pages/consentforms/EditPages/EditIsotretinoinConsentForm.js';
import Loader from './components/Loader/Spin.js';
import TemplateLogo from './components/TemplateLogo/TemplateLogo';
import FoamSclerotherapy from './views/pages/consentforms/FoamSclerotherapy';
import EditFoamSclerotherapy from './views/pages/consentforms/EditPages/EditFoamSclerotherapy';
import VideoConsultation from './views/Video_chats/VideoConsultation';
import DoctorVideoConsultation from './views/Video_chats/DoctorVideoConsultation';
import HealthQuestionnaireOTP from './views/HealthQuestionnaire/HealthQuestionnaireOTP';
import HealthQuestionVerificationOTP from './views/HealthQuestionnaire/HealthQuestionVerificationOTP';
import HealthQuestion from './views/HealthQuestionnaire/HealthQuestion';
import ThankYou from './views/HealthQuestionnaire/ThankYou';
import VideoOTP from './views/Video_chats/VideoOTP';
import VideoVerificationOTP from './views/Video_chats/ViedeoVarificationOTP';
import SpironolactioneSchedule from './views/pages/consentforms/SpironolactioneSchedule';
import MonthlyEODTotal from './views/pages/EODBanking/MonthlyEODTotal';
import DoctorHours from './views/pages/DoctorHours/DoctorHours';
import CiclosporinSchedule from './views/pages/consentforms/CiclosporinSchedule';
import DeleteMergePatient from './views/pages/deleteMerge/DeleteMergePatient';
import RoaccPackMale from './views/pages/consentforms/RoaccPackMale';
import RoaccPackFemale from './views/pages/consentforms/RoaccPackFemale';
import EditSpironolactioneSchedule from './views/pages/consentforms/EditPages/EditSpironolactioneSchedule';
import EditCiclosporinSchedule from './views/pages/consentforms/EditPages/EditCiclosporinSchedule';
import EditRoaccPackMale from './views/pages/consentforms/EditPages/EditRoaccPackMale';
import EditRoaccFemale from './views/pages/consentforms/EditPages/EditRoaccFemale';
import Methotrexate from './views/pages/consentforms/Methotrexate';
import VascularSurgery from './views/pages/consentforms/VascularSurgery';
import EditMethotrexate from './views/pages/consentforms/EditPages/EditMethotrexate';
import EditVascularSurgerySchedule from './views/pages/consentforms/EditPages/EditVascularSurgerySchedule.js';
import ConversionTracker from './views/pages/ConversionTracker/ConversionTracker';
import RoaccPackMaleMessage from './views/pages/consentforms/RoaccPackMaleMessage';
import RoaccFemalePackMessage from './views/pages/consentforms/RoaccFemalePackMessage';
import MethotrexateMessage from './views/pages/consentforms/MethotrexateMessage';
import CiclosporinScheduleMessage from './views/pages/consentforms/CiclosporinScheduleMessage';
import SpironolactioneScheduleMessage from './views/pages/consentforms/SpironolactioneScheduleMessage';
import VascularSurgeryMessage from './views/pages/consentforms/VascularSurgeryMessage.js';
import RoaccPackMaleOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/RoaccPackMaleOTP';
import RoaccPackMaleOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/RoaccPackMaleOTPVerification';
import RoaccPackFemaleOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/RoaccPackFemaleOTP';
import RoaccPackFemaleOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/RoaccPackFemaleOTPVerification';
import CiclosporinScheduleOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/CiclosporinScheduleOTP';
import CiclosporinScheduleOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/CiclosporinScheduleOTPVerification';
import SpironolactioneScheduleOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/SpironolactioneScheduleOTP';
import SpironolactioneScheduleOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/SpironolactioneScheduleOTPVerification';
import MethotrexateOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/MethotrexateOTP';
import MethotrexateOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/MethotrexateOTPVerification';
import VascularSurgeryOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/VascularSurgeryOTP.js';
import VascularSurgeryOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/VascularSurgeryOTPVerification.js';
import TreatmentNoteEdit from './views/pages/TreatmentNoteEdit/TreatmentNoteEdit';
import TaskSummary from './views/pages/TaskSummary/TaskSummary.js';
import Systems from './views/pages/System/Systems.js';
import MarketingDataReport from './views/pages/MarketingDataReport/MarketingDataReport.js';
import { Navbar } from 'react-bootstrap';
import IsotretinoinConsentOTPVerification from './views/pages/consentforms/EditPages/FormsVerificationPage/IsotretinoinConsentOTPVerification.js';
import IsotretinoinConsentOTP from './views/pages/consentforms/EditPages/FormsVerificationPage/IsotretinoinConsentOTP.js';
import IsotretinoinConsentMessage from './views/pages/consentforms/IsotretinoinConsentMessage.js';
import BonusTracker from './views/pages/BonusTracker/BonusTracker.js';
// import  SentPaymentLink  from './views/pages/SentPaymentLink/SentPaymentLink.js';
const SendPaymentLink = React.lazy(
  () => import('./views/pages/SendPaymentLink/SendPaymentLink.js')
);
const ApptTable = React.lazy(() => import('./views/tables/ApptTable'));
const Usersss = React.lazy(() => import('./views/pages/Users/Users'));
const Doctor = React.lazy(() => import('./views/pages/Doctor/Doctor'));
const Role = React.lazy(() => import('./views/pages/Role/Role'));
const DrugList = React.lazy(() => import('./views/pages/DrugList/DrugList'));
const Room = React.lazy(() => import('./views/pages/Room/Room'));
const Service = React.lazy(() => import('./views/pages/Service/Service'));
const TreatmentNotes = React.lazy(
  () => import('./views/pages/TreatmentNotes/TreatmentNotes')
);
const Templates = React.lazy(() => import('./SMS/SMSTemplates/Templates'));
const ReportsTable = React.lazy(() => import('./views/tables/ReportsTable'));
const TreatmentQuestionsDialog = React.lazy(
  () => import('./components/Dialogs/TreatmentQuestionsDialog')
);
const TreatmentEditDialog = React.lazy(
  () => import('./components/Dialogs/TreatmentEditDialog')
);
const ProvisionalAppointment = React.lazy(
  () => import('./views/pages/ProvisionalAppointment')
);
const ReviewAppoinment = React.lazy(
  () => import('./views/pages/ReviewAppoinment')
);
const Patients = React.lazy(() => import('./views/pages/Patients/Patients'));
const Compose = React.lazy(() => import('./SMS/ComposeSMS/Compose'));
const Confermation = React.lazy(
  () => import('./views/pages/consentforms/Confermation')
);
const Question = React.lazy(() => import('./views/forms/Question'));
const SurgeryPriceSchedule = React.lazy(
  () => import('./views/pages/consentforms/SurgeryPriceSchedule')
);
const SteroidInjection = React.lazy(
  () => import('./views/pages/consentforms/SteroidInjection')
);
const EVLAConsentForm = React.lazy(
  () => import('./views/pages/consentforms/EVLAConsentForm')
);
const CryotherapyForm = React.lazy(
  () => import('./views/pages/consentforms/CryotherapyForm')
);
const SclerotherapyConsentForm = React.lazy(
  () => import('./views/pages/consentforms/SclerotherapyConsentForm')
);
const GeneralConsentForm = React.lazy(
  () => import('./views/pages/consentforms/GeneralConsentForm')
);
const CarpalTunnelSurgeryConsentForm = React.lazy(
  () => import('./views/pages/consentforms/CarpalTunnelSurgeryConsentForm')
);
const HairTransplantConsentForm = React.lazy(
  () => import('./views/pages/consentforms/HairTransplantForm')
);
const DermaluxConsentForm = React.lazy(
  () => import('./views/pages/consentforms/DermaluxConsentForm.js')
);
const DermaluxDailyConsentForm = React.lazy(
  () => import('./views/pages/consentforms/DermaluxDailyConsentForm.js')
);
const IsotretinoinConsentForm = React.lazy(
  () => import('./views/pages/consentforms/IsotretinoinConsentForm.js')
);
const EditSteroidInjection = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditSteroidInjection')
);
const EditSurgeryPriceSchedule = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditSurgeryPriceSchedule')
);
const EditCarpalTunnelSurgery = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditCarpalTunnelSurgery')
);
const EditCryotherapy = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditCryotherapy')
);
const EditGeneralConsent = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditGeneralConsent')
);
const EditEVLAConstentForm = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditEVLAConstentForm')
);
const EditHairTransplantForm = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditHairTransplantForm.js')
);
const EditDermaluxConsentForm = React.lazy(
  () =>
    import('./views/pages/consentforms/EditPages/EditDermaluxConsentForm.js')
);

// const EditIsotretinoinConsentForm = React.lazy(
//   () =>
//     import(
//       './views/pages/consentforms/EditPages/EditIsotretinoinConsentForm.js'
//     )
// );
const EditSclerotherapy = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditSclerotherapy')
);
const EditMedicalQuestionnaire = React.lazy(
  () => import('./views/pages/consentforms/EditPages/EditMedicalQuestionnaire')
);
const PatientJob = React.lazy(
  () => import('./views/pages/PatientJob/PatientJob')
);
const JobsList = React.lazy(() => import('./views/pages/JobsList'));
const TemplateNoteEdit = React.lazy(
  () => import('./views/pages/TemplateNoteEdit/TemplateNoteEdit')
);
const LanReqHome = React.lazy(
  () => import('./views/pages/Profile/LabTest/LanReqHome')
);
const LeadManagement = React.lazy(
  () => import('./views/pages/LeadManagement/LeadManagement')
);
const EODBanking = React.lazy(
  () => import('./views/pages/EODBanking/EODBanking')
);
const ViewReportPage = React.lazy(
  () => import('./views/pages/Profile/LabTest/ViewReportPage/ViewReportPage')
);
const BasicTabs = React.lazy(() => import('./Tab/Tab'));

const isLogin = JSON.parse(localStorage.getItem('isLogin'));
const users = JSON.parse(localStorage.getItem('user_info'));

export const Navigation = () => (
  <Router>
    <Routes>
      <Route
        path='*'
        element={
          users && users.roleID !== 14 ? (
            <Navigate to='/diary' replace />
          ) : (
            <Navigate to='/lead-management' />
          )
        }
      />
      <Route exact path='/' element={<Auth />} />
      <Route exact path='/otp' element={<Auth />} />
      <Route path='/templateLogo' element={<TemplateLogo />} />

      <Route
        path='/thankyou'
        element={
          <Suspense fallback={<Loader />}>
            <ThankYou />
          </Suspense>
        }
      />

      <Route
        path='/health-QuestionnaireOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <HealthQuestionnaireOTP />
          </Suspense>
        }
      />
      <Route
        path='/health-QuestionnaireOTP-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <HealthQuestionVerificationOTP />
          </Suspense>
        }
      />
      <Route
        path='/RoaccPackMaleOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <RoaccPackMaleOTP />
          </Suspense>
        }
      />
      <Route
        path='/RoaccPackMaleOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <RoaccPackMaleOTPVerification />
          </Suspense>
        }
      />
      <Route
        path='/RoaccPackFemaleOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <RoaccPackFemaleOTP />
          </Suspense>
        }
      />
      <Route
        path='/RoaccPackFemaleOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <RoaccPackFemaleOTPVerification />
          </Suspense>
        }
      />
      {/* IsotretinoinConsentOTP---------------------------- */}
      <Route
        path='/IsotretinoinConsentOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <IsotretinoinConsentOTP />
          </Suspense>
        }
      />
      <Route
        path='/IsotretinoinConsentOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <IsotretinoinConsentOTPVerification />
          </Suspense>
        }
      />
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/IsotretinoinConsentMessage'
          element={
            <Suspense fallback={<Loader />}>
              <IsotretinoinConsentMessage />
            </Suspense>
          }
        />
      )}
      {/* IsotretinoinConsentOTP------------end---------------- */}
      <Route
        path='/CiclosporinScheduleOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <CiclosporinScheduleOTP />
          </Suspense>
        }
      />
      <Route
        path='/CiclosporinScheduleOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <CiclosporinScheduleOTPVerification />
          </Suspense>
        }
      />
      <Route
        path='/SpironolactioneScheduleOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <SpironolactioneScheduleOTP />
          </Suspense>
        }
      />
      <Route
        path='/SpironolactioneScheduleOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <SpironolactioneScheduleOTPVerification />
          </Suspense>
        }
      />
      <Route
        path='/MethotrexateOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <MethotrexateOTP />
          </Suspense>
        }
      />
      <Route
        path='/MethotrexateOTPVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <MethotrexateOTPVerification />
          </Suspense>
        }
      />
      <Route
        path='/VascularSurgeryOTP/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <VascularSurgeryOTP />
          </Suspense>
        }
      />
      <Route
        path='/VascularSurgeryVerification-verification/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <VascularSurgeryOTPVerification />
          </Suspense>
        }
      />
      <Route
        path='/health-Questionnaire/:id/appId/:appId'
        element={
          <Suspense fallback={<Loader />}>
            <HealthQuestion />
          </Suspense>
        }
      />
      {/* video start  */}
      <Route
        path='/video-OTP/:id/roomId/:roomId'
        element={
          <Suspense fallback={<Loader />}>
            <VideoOTP />
          </Suspense>
        }
      />
      <Route
        path='/video-OTP-verification/:id/roomId/:roomId'
        element={
          <Suspense fallback={<Loader />}>
            <VideoVerificationOTP />
          </Suspense>
        }
      />
      <Route
        path='/video-consultation-roomId/:id/roomId/:roomId'
        element={
          <Suspense fallback={<Loader />}>
            <VideoConsultation />
          </Suspense>
        }
      />
      <Route
        path='/doctor-video-consultation-roomId/:id'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <Suspense fallback={<Loader />}>
                <DoctorVideoConsultation />
              </Suspense>
            </AdminLayout>
          </ProtectedRoute>
        }
      />
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/diary'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <ApptTable />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {/* Masters start  ************************************************************************************/}
      {(users && users?.roleID === 8) ||
      (users && users?.roleID === 9) ||
      (users && users?.roleID !== 10) ? (
        <Route
          exact
          path='/users'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Usersss />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/doctor'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Doctor />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/role'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Role />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/drug-list'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <DrugList />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/room'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Room />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {/* {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path="/systems"
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Chamber />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
       <Route path="*" element={<Navigate to="/diary" replace />} />
      )} */}
      {/* For admin visibility only */}
      {/* { (users && users?.roleID === 8) || (users && users?.roleID === 9) ? ( */}
      {users && users?.roleID === 8 ? (
        <Route
          exact
          path='/ManagementPin'
          element={
            <AdminLayout>
              <Systems />
            </AdminLayout>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}

      <Route
        path='/PhonePayments'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <Suspense fallback={<Loader />}>
              <SendPaymentLink />
            </Suspense>
          </ProtectedRoute>
        }
      />

      {(users && users?.roleID === 8) ||
      (users && users?.roleID === 9) ||
      (users && users?.roleID === 7) ? (
        <Route
          exact
          path='/rota'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Chamber />
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/service'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Service />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/templates'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <TreatmentNotes />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {(users && users?.roleID === 8) || (users && users?.roleID === 9) ? (
        <Route
          exact
          path='/sms-templates'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Templates />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {users && users?.roleID === 8 ? (
        <Route
          exact
          path='/delete-merge-patient'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <DeleteMergePatient />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      ) : (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      )}
      {/* Masters end ***********************************************************************************  */}
      {/* {Reports start **********************************************************************************} */}
      {users?.roleID !== 8 ? (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      ) : (
        <Route
          exact
          path='/reports'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <ReportsTable />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {/* {Reports end **********************************************************************************} */}
      {users && users !== 14 && (
        <Route
          exact
          path='/templateQuestions/:id/appoId/:appID/tempId/:tid/:tempName'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <TreatmentQuestionsDialog />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users !== 14 && (
        <Route
          exact
          path='/templateEdit/treatid/:treatid/templId/:tempId/patientID/:id/appoId/:aId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <TreatmentNoteEdit />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {/* <Route
        exact
        path="/home"
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <Home />
            </AdminLayout>
          </ProtectedRoute>
        }
      /> */}
      {users && users !== 14 && (
        <Route
          exact
          path='/templateNotes'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <TreatmentEditDialog />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/provisionals'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <ProvisionalAppointment />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/MarketingDataReport'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <MarketingDataReport />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/reviews'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <ReviewAppoinment />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/patients'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Patients />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users?.roleID !== 14 && (
        <Route
          exact
          path='/profile/:id'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <BasicTabs />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users?.roleID === 1 ||
      users?.roleID === 2 ||
      users?.roleID === 3 ||
      users?.roleID === 5 ||
      users?.roleID === 13 ||
      users?.roleID === 14 ? (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      ) : (
        <Route
          exact
          path='/compose-sms'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <Compose />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {/* consent-forms start  ***************************************************** */}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/confirmation'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <Confermation />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <Question />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/steroid-injection-form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <SteroidInjection />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/surgery-price-schedule-form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <SurgeryPriceSchedule />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/EVLA-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <EVLAConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Cryotherapy-form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <CryotherapyForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Microsclerotherapy-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <SclerotherapyConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/FoamSclerotherapy-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <FoamSclerotherapy />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/General-Surgery-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <GeneralConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Dermalux-Initial-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <DermaluxConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Dermalux-Daily-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <DermaluxDailyConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Isotretinoin-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <IsotretinoinConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Carpal-Tunnel-Surgery-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <CarpalTunnelSurgeryConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Hair-Transplant-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <HairTransplantConsentForm />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Spironolactione-Schedule-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <SpironolactioneSchedule />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Ciclosporin-Schedule-Consent-Form'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <CiclosporinSchedule />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Roacc-Pack-Male'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <RoaccPackMale />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Roacc-Pack-Female'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <RoaccPackFemale />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/Methotrexate'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <Methotrexate />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/:id/VascularSurgery'
          element={
            <ProtectedRoute isLogin={isLogin}>
              {/* <AdminLayout> */}
              <Suspense fallback={<Loader />}>
                <VascularSurgery />
              </Suspense>
              {/* </AdminLayout> */}
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/RoaccPackMaleMessage'
          element={
            <Suspense fallback={<Loader />}>
              <RoaccPackMaleMessage />
            </Suspense>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/RoaccPackFemaleMessage'
          element={
            <Suspense fallback={<Loader />}>
              <RoaccFemalePackMessage />
            </Suspense>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/MethotrexateMessage'
          element={
            <Suspense fallback={<Loader />}>
              <MethotrexateMessage />
            </Suspense>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/CiclosporinScheduleMessage'
          element={
            <Suspense fallback={<Loader />}>
              <CiclosporinScheduleMessage />
            </Suspense>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/SpironolactioneScheduleMessage'
          element={
            <Suspense fallback={<Loader />}>
              <SpironolactioneScheduleMessage />
            </Suspense>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/consent-forms/patieId/:pid/appointId/:aid/VascularSurgeryMessage'
          element={
            <Suspense fallback={<Loader />}>
              <VascularSurgeryMessage />
            </Suspense>
          }
        />
      )}
      {/* consent-forms  start  ***************************************************** */}
      {/* consent-forms View start  ***************************************************** */}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/steroid-injection-form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditSteroidInjection />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/surgery-price-schedule-form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditSurgeryPriceSchedule />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Carpal-Tunnel-Surgery-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditCarpalTunnelSurgery />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Cryotherapy-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditCryotherapy />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/General-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditGeneralConsent />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/EVLA-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditEVLAConstentForm />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Hair-Transplant-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditHairTransplantForm />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Dermalux-Initial-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditDermaluxConsentForm />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Dermalux-Daily-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditDermaluxDailyConsentForm />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Isotretinoin-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditIsotretinoinConsentForm />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Microsclerotherapy-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditSclerotherapy />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Medical-Questionnaire-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditMedicalQuestionnaire />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Foam-Sclerotherapy-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditFoamSclerotherapy />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Foam-Sclerotherapy-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditSclerotherapy />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Spironolactone-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditSpironolactioneSchedule />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Isotretinoin-Male-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditRoaccPackMale />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Isotretinoin-Female-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditRoaccFemale />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Ciclosporin-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditCiclosporinSchedule />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Methotrexate-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditMethotrexate />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/view-consent-forms/:id/Vascular-Surgery-Consent-Form-id/:viewId'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EditVascularSurgerySchedule />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {/* consent-forms View  END  ***************************************************** end*/}

      {users && users.roleID !== 14 && (
        <Route
          path='/tasks/'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  {/* <TaskSummary /> */}
                  <PatientJob />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      <Route
        path='/tasks/:id'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <Suspense fallback={<Loader />}>
                <PatientJob />
              </Suspense>
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      {users && users.roleID !== 14 && (
        <Route
          path='/JobsList'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <JobsList />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/viewTemplateNote'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <TemplateNoteEdit />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {/* <Route
        exact
        path="/LabTestForm"
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <LabTestForm />
            </AdminLayout>
          </ProtectedRoute>
        }
      /> */}

      {users && users.roleID !== 14 && (
        <Route
          path='/LabTest'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <LanReqHome />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      <Route
        exact
        path='/lead-management'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <Suspense fallback={<Loader />}>
                <LeadManagement />
              </Suspense>
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='/conversion-tracker'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <ConversionTracker />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
      {/* {users && users.roleID !== 14 && (
        <Route
          exact
          path='/bonus-tracker'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <BonusTracker />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )} */}
      {(users && users?.roleID === 6) ||
      (users && users?.roleID === 1) ||
      (users && users?.roleID === 2) ||
      (users && users?.roleID === 3) ||
      (users && users?.roleID === 10) ||
      (users && users?.roleID === 5) ||
      (users && users?.roleID === 13) ||
      (users && users?.roleID === 14) ? (
        <Route path='*' element={<Navigate to='/diary' replace />} />
      ) : (
        <Route
          exact
          path='/EODBanking'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <EODBanking />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      <Route
        exact
        path='/view-report/:id/patientId/:patientId'
        element={
          <ProtectedRoute isLogin={isLogin}>
            <AdminLayout>
              <Suspense fallback={<Loader />}>
                <ViewReportPage />
              </Suspense>
            </AdminLayout>
          </ProtectedRoute>
        }
      />

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='//Monthly-EOD-Total'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <MonthlyEODTotal />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}

      {users && users.roleID !== 14 && (
        <Route
          exact
          path='//Doctor-Hours'
          element={
            <ProtectedRoute isLogin={isLogin}>
              <AdminLayout>
                <Suspense fallback={<Loader />}>
                  <DoctorHours />
                </Suspense>
              </AdminLayout>
            </ProtectedRoute>
          }
        />
      )}
    </Routes>
  </Router>
);

export default Navigation;
